import React from 'react';
import YAMLData from '../../data/solutions/devops.yaml'
import Page from './_solution_';

function AIPage() {

    return (
      <Page content={YAMLData} />
    );
  }
  
  export default AIPage;
  